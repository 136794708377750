import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconContext } from "react-icons";

import {
  Drawer as MuiDrawer,
  Box,
  Stack,
  List,
  IconButton,
  ListSubheader,
} from "@mui/material";

import {
  // IoSpeedometerOutline as DashboardIcon,
  IoSettingsOutline as ConfigurationIcon,
  IoCash as PaymentAccountIcon,
} from "react-icons/io5";
import {
  RiUserLine as UserIcon,
  RiDashboardLine as DashboardIcon,
} from "react-icons/ri";
import {
  FaHandHoldingUsd as CollectionIcon,
  FaLuggageCart as SupplierIcon,
} from "react-icons/fa";
import {
  // GiFarmTractor as FarmIcon,
  GiTakeMyMoney as CashAdvanceIcon,
} from "react-icons/gi";
// import { MdPeopleOutline as ReferralIcon } from "react-icons/md";
import {
  CgMenuLeftAlt as MenuIcon,
  CgClose as CloseIcon,
} from "react-icons/cg";

// import DraftsIcon from "@mui/icons-material/Drafts";

import CListItem from "./common/CustomListItem";
// import Can from "../utils/rbac/Can";
import UserAvatar from "./common/UserAvatar";

// import { defaultUser } from "../data/AuthUser";
import { theme } from "../context/ThemeProvider";

import tradeBuzaLogo from "../assets/cropgate_black.png";
import AppActions from "../redux/actions/app.action";
import { getAuthUser } from "../utils/storage";

// import { getUserProfile } from "../redux/actions/auth.action";
// import { getAllPermission } from "../redux/actions/AuthActions";

const CustomListItem = (props) => {
  const { sx = {}, ...rest } = props;
  return <CListItem sx={{ ...sx }} {...rest} />;
};

const DrawerContent = ({ closeHandler }) => {
  // const [authenticatedUser, setAuthenticatedUser] = useState(defaultUser);
  const authenticatedUser = useSelector(
    (state) => state?.Auth?.userProfile || getAuthUser()
  );
  // const rolePermissions = authenticatedUser?.myPermissions;
  const listSelected = useSelector((state) =>
    parseInt(state?.App?.menuSelected, 10)
  );

  const dispatch = useDispatch();
  // console.log(theme.palette.primary.main);
  const handleListItemClick = (index) => () => {
    dispatch(AppActions.setCurrentDrawerMenu(index));
  };

  const imgLogo = JSON.parse(localStorage.getItem("user_profile"));

  console.log("imgLogo", imgLogo?.tenant_profile?.logo);

  useEffect(() => {
    // dispatch(getAllPermission());
    // dispatch(getUserProfile());
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        color: "#fff",
        // px: 2,
      }}
    >
      <List
        sx={{ width: "100%" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ padding: "1rem 10px" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <p></p>
              {imgLogo && (
                <img
                  src={`${
                    imgLogo?.tenant_profile?.logo
                      ? imgLogo?.tenant_profile?.logo
                      : tradeBuzaLogo
                  }`}
                  alt="tradebuza logo"
                  id="drawer-logo"
                />
              )}
              <IconContext.Provider
                value={{ size: "28px", color: `${theme.palette.primary.main}` }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {}}
                  edge="start"
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={closeHandler}
                  edge="start"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </IconContext.Provider>
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ padding: "32px 0 40px" }}
            >
              <UserAvatar
                size="78px"
                fontSize="32px"
                firstname={authenticatedUser?.first_name || ""}
                lastname={authenticatedUser?.last_name || ""}
              />
            </Stack>
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              <CustomListItem
                icon={<DashboardIcon />}
                text="Dashboard"
                href="/dashboard-crop-gate/overview"
                selected={listSelected === 12}
                onClick={handleListItemClick(12)}
              />

              {/* <CustomListItem
                icon={<DashboardIcon />}
                text="Referral Dashboard"
                href="/referrals/dashboard"
                selected={listSelected === 11}
                onClick={handleListItemClick(11)}
              /> */}

              <CustomListItem
                icon={<SupplierIcon />}
                text="Supplier Management"
                href="/crop-gate/supplier-management"
                selected={listSelected === 16}
                onClick={handleListItemClick(16)}
              />

              <CustomListItem
                icon={<CollectionIcon />}
                text="Collections"
                href="/crop-gate/collections"
                selected={listSelected === 13}
                onClick={handleListItemClick(13)}
              />

              <CustomListItem
                icon={<PaymentAccountIcon />}
                text="Payments & Accounts"
                href="/crop-gate/payment-account"
                selected={listSelected === 17}
                onClick={handleListItemClick(17)}
              />

              <CustomListItem
                icon={<CashAdvanceIcon />}
                text="Cash Advance"
                href="/crop-gate/cash-advance"
                selected={listSelected === 14}
                onClick={handleListItemClick(14)}
              />

              <CustomListItem
                icon={<SupplierIcon />}
                text="Assignments"
                href="/crop-gate/assignment"
                selected={listSelected === 18}
                onClick={handleListItemClick(18)}
              />

              {/* <CustomListItem
                icon={<ReferralIcon />}
                text="Referral"
                href="/crop-gate/referral-partners"
                selected={listSelected === 18}
                onClick={handleListItemClick(18)}
              /> */}

              {/* <CustomListItem
                icon={<DashboardIcon />}
                text="Earnings"
                href="/referrals/earnings"
                selected={listSelected === 2}
                onClick={handleListItemClick(2)}
              /> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 2, 3, 4].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>

      <List
        sx={{
          width: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="h2"
            id="nested-list-subheader"
            // sx={{ padding: "1rem 10px" }}
            sx={{
              padding: "1rem 0 0 15px",
              backgroundColor: "transparent",
              color: "inherit",
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            YOUR ACCOUNT
          </ListSubheader>
        }
      >
        <IconContext.Provider value={{ className: "drawer-icons" }}>
          {Object.keys(authenticatedUser).length > 0 ? (
            <React.Fragment>
              <CustomListItem
                icon={<UserIcon />}
                text="User Management"
                href="/crop-gate/user-management/users"
                selected={listSelected === 15}
                onClick={handleListItemClick(15)}
              ></CustomListItem>
              <CustomListItem
                icon={<ConfigurationIcon />}
                text="Settings"
                href="/crop-gate/settings"
                selected={listSelected === 1}
                onClick={handleListItemClick(1)}
              ></CustomListItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {[1, 2].map((item, index) => (
                <div key={index} className="flex flex-row mb-6 px-5">
                  <div
                    className="lazy-loading p-3 mr-4"
                    style={{
                      borderRadius: "5px",
                      opacity: 0.8,
                      width: "20px",
                      height: "20px",
                    }}
                  ></div>
                  <div
                    className="lazy-loading p-3"
                    style={{ borderRadius: "5vh", opacity: 0.8, flexGrow: 1 }}
                  ></div>
                </div>
              ))}
            </React.Fragment>
          )}
        </IconContext.Provider>
      </List>
    </Stack>
  );
};

function Drawer({ isOpen, toggler }) {
  const drawerWidth = 305;
  return (
    <Box
      component="nav"
      id="main-drawer"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="Navigation Options"
    >
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={toggler}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        open
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {<DrawerContent closeHandler={toggler} />}
      </MuiDrawer>
    </Box>
  );
}

export default Drawer;
