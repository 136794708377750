import React from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";

const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const RegEmailSent = React.lazy(() => import("./pages/RegEmailSent"));
const CreatePassword = React.lazy(() => import("./pages/CreatePassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndCondition")
);

// CROP GATE
const DashboardIndexSection = React.lazy(() =>
  import("./layouts/Dashboard/DashboardCropGate")
);

const CompleteCompanyProfile = React.lazy(() =>
  import("./layouts/Dashboard/CompleteCompanyProfile")
);

const SelectAccountType = React.lazy(() =>
  import("./layouts/Dashboard/SelectAccount")
);

const SoleProprietorship = React.lazy(() =>
  import("./layouts/Dashboard/SoleProprietorship")
);

const LimitedLiabilityCompany = React.lazy(() =>
  import("./layouts/Dashboard/LimitedLiabilityCompany")
);

const ReferralPartnerDashboard = React.lazy(() =>
  import("./layouts/Dashboard/DashboardReferralPartners")
);

const ReferralEarnings = React.lazy(() =>
  import("./layouts/ReferralEarnings/EarningsIndex")
);

const AddNewAccount = React.lazy(() =>
  import("./layouts/ReferralEarnings/AddAccount")
);

const CropGateUsers = React.lazy(() =>
  import("./layouts/UserManagement/Users")
);

const CropGateReferalPartners = React.lazy(() =>
  import("./layouts/Referral/ReferralPartners")
);

const CropGateSuppliers = React.lazy(() =>
  import("./layouts/SupplierManagement/Suppliers")
);

const CropGateViewSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/ViewSupplier")
);

const ViewFarmer = React.lazy(() =>
  import("./layouts/SupplierManagement/ViewFarmer")
);

const EditFarmer = React.lazy(() =>
  import("./layouts/SupplierManagement/EditFarmer")
);

const CropGateAddSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/AddSupplier")
);

const CropGateAddFarmer = React.lazy(() =>
  import("./layouts/FarmerManagement/AddFarmer")
);

const CropGateEditSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/EditSupplier")
);

const CropGateSupplies = React.lazy(() =>
  import("./layouts/Collections/Supplies")
);

const CropGateViewSupplies = React.lazy(() =>
  import("./layouts/Collections/ViewSupplies")
);

const CropGatePayments = React.lazy(() =>
  import("./layouts/PaymentAccount/Index")
);

const CropGateViewPaymentSupplies = React.lazy(() =>
  import("./layouts/PaymentAccount/ViewSuppliesDetails")
);

const AddNewUser = React.lazy(() =>
  import("./layouts/UserManagement/AddNewUser")
);

const AddNewTenant = React.lazy(() => import("./layouts/Tenants/AddNewTenant"));

const AddReferralPartner = React.lazy(() =>
  import("./layouts/Referral/AddReferralPartner")
);

const UserDetails = React.lazy(() =>
  import("./layouts/UserManagement/UserDetails")
);

const CashAdvance = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvance")
);

const CashAdvanceSupplierDetails = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvanceSupplierDetails")
);

const EditInformation = React.lazy(() => import("./layouts/Settings/Index"));

const AddNewPin = React.lazy(() => import("./layouts/Settings/AddNewPin"));

const AddNewPassword = React.lazy(() =>
  import("./layouts/Settings/AddNewPassword")
);

// Import Assignment Components
const Assignment = React.lazy(() => import("./layouts/Assignment/Assignment"));
const AssignmentViewFarmer = React.lazy(() =>
  import("./layouts/Assignment/AssignmentViewFarmer")
);
const AssignmentViewSupplier = React.lazy(() =>
  import("./layouts/Assignment/AssignmentViewSupplier")
);

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Router>
            <Switch>
              <Route exact path="/" element={<Login />} />

              <Route path="/register" element={<Register />} />

              <Route path="/login" element={<Login />} />

              <Route path="/email_sent" element={<RegEmailSent />} />

              <Route
                path="/confirm/email/:id/:id"
                element={<CreatePassword />}
              />

              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />

              <Route
                path="/forgot/password/:id/:id"
                element={<ResetPassword />}
              />

              {/* CROP GATE DASHBOARD */}
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<DashboardIndexSection />} />
              </Route>

              <Route
                path="/dashboard-crop-gate"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<DashboardIndexSection />} />
                <Route path="overview" element={<DashboardIndexSection />} />
                <Route
                  path="complete-company-profile"
                  element={<CompleteCompanyProfile />}
                />
                <Route path="select-account" element={<SelectAccountType />} />
                <Route
                  path="complete-company-profile/sole-proprietorship"
                  element={<SoleProprietorship />}
                />

                <Route
                  path="complete-company-profile/limited-liability-company"
                  element={<LimitedLiabilityCompany />}
                />
              </Route>

              {/* CROP GATE */}
              <Route
                path="/crop-gate"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<DashboardIndexSection />} />
                <Route path="settings" element={<EditInformation />} />
                <Route path="settings/add-pin" element={<AddNewPin />} />
                <Route
                  path="settings/add-password"
                  element={<AddNewPassword />}
                />
                <Route
                  path="user-management/users"
                  element={<CropGateUsers />}
                />
                <Route
                  path="referral-partners"
                  element={<CropGateReferalPartners />}
                />
                <Route
                  path="referral-partners/add-referral-partner"
                  element={<AddReferralPartner />}
                />
                <Route
                  path="user-management/add-user"
                  element={<AddNewUser />}
                />
                <Route
                  path="user-management/add-tenant"
                  element={<AddNewTenant />}
                />
                <Route
                  path="user-management/user/:userId"
                  element={<UserDetails />}
                />
                <Route
                  path="supplier-management"
                  element={<CropGateSuppliers />}
                />
                <Route
                  path="supplier-management/add-supplier"
                  element={<CropGateAddSupplier />}
                />
                <Route
                  path="supplier-management/add-farmer"
                  element={<CropGateAddFarmer />}
                />
                <Route
                  path="supplier-management/update-supplier/:supplierId"
                  element={<CropGateEditSupplier />}
                />
                <Route
                  path="supplier-management/suppliers/:supplierId"
                  element={<CropGateViewSupplier />}
                />
                <Route
                  path="supplier-management/farmers/:farmerId"
                  element={<ViewFarmer />}
                />
                <Route
                  path="supplier-management/update-farmer/:farmerId"
                  element={<EditFarmer />}
                />
                <Route path="collections" element={<CropGateSupplies />} />
                <Route
                  path="collections/supplies/:supplyId"
                  element={<CropGateViewSupplies />}
                />
                <Route path="cash-advance" element={<CashAdvance />} />
                <Route
                  path="cash-advance/supplier/:id"
                  element={<CashAdvanceSupplierDetails />}
                />
                <Route path="payment-account" element={<CropGatePayments />} />
                <Route
                  path="payment-account/supplies/:supplyId"
                  element={<CropGateViewPaymentSupplies />}
                />
                <Route path="assignment" element={<Assignment />} />
                <Route path="farmers" element={<AssignmentViewFarmer />} />
                <Route path="suppliers" element={<AssignmentViewSupplier />} />
              </Route>

              <Route
                path="/referrals"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={<ReferralPartnerDashboard />}
                />
                <Route path="earnings" element={<ReferralEarnings />} />
                <Route path="new-account" element={<AddNewAccount />} />
              </Route>

              {/* Assignment */}
              <Route
                path="/assignment"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<Assignment />} />
                <Route path="farmers" element={<AssignmentViewFarmer />} />
                <Route path="suppliers" element={<AssignmentViewSupplier />} />
              </Route>
              {/* Assignment End */}

              <Route path="*" element={<NoPage />} />
            </Switch>
          </Router>
          <ToastContainer />
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
